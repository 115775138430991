import React from "react";
import OurPage from "../assets/Images/ourPage.jpg";

const Our = () => {
  return (
    <div>
      <div id="about">
        <div className="about-image">
          <img
            src="https://media.istockphoto.com/id/1384098518/photo/real-estate-professionals-offer-their-clients-contracts-to-discuss-home-purchases-insurance.jpg?s=612x612&w=0&k=20&c=zqUE2VL0SvnUM8iIKVXBes-mBjcjr35IY6AAwGbAp9g="
            alt=""
            className=" img-fluid w-100 h-100"
          />
        </div>
        <div className="about-text">
          <h2>OUR LONG-TERM PLANS IN TERMS OF EMPLOYMENT</h2>
          <p>
            Being an innovative real estate investment company, we have a
            technical team of experts working round the corner to ensure minimum
            technical faults and inconvenience to the users. In future, we are
            planning to hire local talent from nearby cities, especially female
            candidates to generate employment. We would also rely network of
            freelancers, contractors, and consultants to support our operations.
            This creates opportunities for people with specialized skills and
            experience to find work on a flexible basis. We would also be adding
            a sales team responsible for selling or leasing properties. This
            includes real estate agents, brokers, marketing professionals, and
            customer service representatives to facilitate transactions. We
            would also provide employment opportunities to professionals in
            finance and investment analysis to evaluate potential real estate
            investments, manage financial transactions, and optimize the
            company's investment portfolio.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Our;
